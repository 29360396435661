// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-download-tsx": () => import("./../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitation-tsx": () => import("./../src/pages/invitation.tsx" /* webpackChunkName: "component---src-pages-invitation-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-protocol-tsx": () => import("./../src/pages/protocol.tsx" /* webpackChunkName: "component---src-pages-protocol-tsx" */),
  "component---src-pages-wechat-pay-middle-page-index-tsx": () => import("./../src/pages/wechat-pay-middle-page/index.tsx" /* webpackChunkName: "component---src-pages-wechat-pay-middle-page-index-tsx" */),
  "component---src-pages-wechat-pay-middle-page-order-status-tsx": () => import("./../src/pages/wechat-pay-middle-page/OrderStatus.tsx" /* webpackChunkName: "component---src-pages-wechat-pay-middle-page-order-status-tsx" */),
  "component---src-pages-wechat-pay-middle-page-use-make-order-ts": () => import("./../src/pages/wechat-pay-middle-page/useMakeOrder.ts" /* webpackChunkName: "component---src-pages-wechat-pay-middle-page-use-make-order-ts" */)
}

